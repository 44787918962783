import * as React from "react";

import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import { CollectionWizard } from "../component/wizard/CollectionWizard";

import { PreviewBoard } from "../component/preview/PreviewBoard";
import { FluxReport, Configuration, CaseContext } from "../common/types/models";
import { listConfigurations, listReports } from "../api/savedReports";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { GenerateMemo } from "../component/GenerateMemo";
import { ConfigurationTable } from "../component/table/ConfigurationTable";
import { ProcessModel } from "../component/ProcessModel";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { listCaseContexts } from "../api/caseContext";
import { SavedReportTable } from "../component/table/SavedReportTable";

export const DemoView = () => {
  const [tableLoading, setTableLoading] = React.useState(false);
  const [currentReport, setCurrentReport] = React.useState<FluxReport | null>(null);
  const [savedReports, setSavedReport] = React.useState<FluxReport[]>([]);
  const [configurations, setConfigurations] = React.useState<Configuration[]>([]);
  const [configLoading, setConfigLoading] = React.useState(false);
  const [notifications, setNotifications] = React.useState<FlashbarProps.MessageDefinition[]>([]);
  const [caseContexts, setCaseContexts] = React.useState<CaseContext[]>([]);

  const refreshTable = async () => {
    setTableLoading(true);
    const reports = await listReports("demo-user");
    setSavedReport(reports);
    setTableLoading(false);
  };

  const refreshConfigurations = async () => {
    setConfigLoading(true);
    const configurations = await listConfigurations("demo-user");
    setConfigurations(configurations);
    setConfigLoading(false);
  };

  React.useEffect(() => {
    refreshConfigurations();
  }, []);

  React.useEffect(() => {
    refreshTable();
  }, []);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Product Demo
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <CollectionWizard
          onSubmit={() => {}}
          onSuccess={(report: FluxReport) => {
            setCurrentReport(report);
            refreshTable();
          }}
          onCancel={() => {}}
        />

        <PreviewBoard
          report={currentReport}
          onSave={(report) => {
            setCurrentReport(report);
            refreshTable();
          }}
        />

        <SavedReportTable
          items={savedReports}
          onRefresh={refreshTable}
          onItemClicked={(report) => {
            setCurrentReport(report);
          }}
          loading={tableLoading}
        />
        <ConfigurationTable items={configurations} loading={configLoading} onRefresh={refreshConfigurations} onItemClicked={(item) => {}} />
        <ProcessModel
          setNotifications={(items) => {
            setNotifications([...notifications, ...items]);
          }}
        />
        <GenerateMemo />
      </SpaceBetween>
    </ContentLayout>
  );
};
