import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import { FunctionComponent, useEffect, useState } from "react";
import Link from "@cloudscape-design/components/link";
import { FluxReport } from "../../common/types/models";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import { deleteReport } from "../../api/savedReports";
import { FluxTableWidgetProps } from "../../common/standards";

const replaceSpaceWithDash = (str: string) => str.replace(/\s/g, "-");

export const SavedReportTable: FunctionComponent<FluxTableWidgetProps<FluxReport>> = ({ items, onRefresh, onItemClicked, loading }) => {
  const [selectedItems, setSelectedItems] = useState<FluxReport[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItems([]);
  }, [items]);

  const handleDelete = async (selectedItems: FluxReport[]) => {
    setIsDeleting(true);
    const deleteResults = await Promise.allSettled(selectedItems.map((report) => deleteReport(report.reportId, report.userId)));
    setIsDeleting(false);
    onRefresh();
  };

  const handleDownload = () => {
    selectedItems.forEach((item) => {
      const file_name = `${replaceSpaceWithDash(item.reportMetadata.reportName)}-${item.reportId}.json`;
      const json = JSON.stringify(item, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file_name;
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <Table
      renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) => `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`}
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) => `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`,
        itemSelectionLabel: ({ selectedItems }, item) => item.reportId,
      }}
      loading={loading}
      columnDefinitions={[
        {
          id: "reportId",
          header: "Report ID",
          cell: (item) => item.reportId,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => <Link onClick={() => onItemClicked(item)}>{item.reportMetadata.reportName}</Link>,
        },
        {
          id: "description",
          header: "Description",
          cell: (item) => item.reportMetadata.reportDescription,
        },
      ]}
      columnDisplay={[
        { id: "reportId", visible: true },
        { id: "name", visible: true },
        { id: "description", visible: true },
      ]}
      enableKeyboardNavigation
      items={items}
      loadingText="Loading reports..."
      selectionType="multi"
      trackBy="reportId"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      filter={<TextFilter filteringPlaceholder="Find resources" filteringText="" />}
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" onClick={() => onRefresh()} />
              <ButtonDropdown
                onItemClick={handleDownload}
                disabled={selectedItems.length === 0}
                items={[
                  {
                    text: "JSON",
                    id: "json",
                    disabled: false,
                  },
                ]}
              >
                Download As
              </ButtonDropdown>
              <Button disabled={selectedItems.length === 0} onClick={() => handleDelete(selectedItems)} loading={isDeleting}>
                Delete
              </Button>
              <Button variant="primary" disabled={selectedItems.length === 0}>
                View Details
              </Button>
            </SpaceBetween>
          }
        >
          Saved Reports
        </Header>
      }
      pagination={<Pagination currentPageIndex={1} pagesCount={1} />}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: [
              { id: "reportId", visible: true },
              { id: "name", visible: true },
              { id: "description", visible: true },
            ],
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
            ],
          }}
          wrapLinesPreference={{}}
          stripedRowsPreference={{}}
          contentDensityPreference={{}}
          contentDisplayPreference={{
            options: [
              {
                id: "reportId",
                label: "Report ID",
              },
              {
                id: "name",
                label: "Name",
              },
              {
                id: "description",
                label: "Description",
              },
            ],
          }}
          stickyColumnsPreference={{
            firstColumns: {
              title: "Stick first column(s)",
              description: "Keep the first column(s) visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "First column", value: 1 },
                { label: "First two columns", value: 2 },
              ],
            },
            lastColumns: {
              title: "Stick last column",
              description: "Keep the last column visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "Last column", value: 1 },
              ],
            },
          }}
        />
      }
    />
  );
};
