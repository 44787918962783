import React from "react";
import "./App.css";
import "@cloudscape-design/global-styles/index.css";
import { AppLayout, Container, Flashbar, HelpPanel, SplitPanel } from "@cloudscape-design/components";
import { FluxSideNav } from "./component/SideNav";
import { HashRouter, Route, Routes } from "react-router-dom";
import { DemoView } from "./pages/Demo";
import { FluxBreadcrumbs } from "./component/Breadcrumbs";
import { ConsoleRouteConfig } from "./common/routes";
import { HomeView } from "./pages/Home";

import { I18nProvider } from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.all";
import { ConfigurationView } from "./pages/Configuration";
import { CaseView } from "./pages/Case";

export default function App() {
  const [navOpen, setNavOpen] = React.useState(false);

  return (
    <I18nProvider messages={[messages]}>
      <HashRouter>
        <AppLayout
          headerVariant="high-contrast"
          breadcrumbs={<FluxBreadcrumbs />}
          navigationOpen={navOpen}
          onNavigationChange={(e) => setNavOpen(e.detail.open)}
          navigation={<FluxSideNav />}
          notifications={
            <Flashbar
              items={
                [
                  // {
                  //   type: "info",
                  //   dismissible: true,
                  //   content: "This is an info flash message.",
                  //   id: "message_1",
                  // },
                ]
              }
            />
          }
          // toolsOpen={true}
          // tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
          content={
            <Routes>
              <Route path={ConsoleRouteConfig.home.href} element={<HomeView />} />
              <Route path={ConsoleRouteConfig.demo.href} element={<DemoView />} />
              <Route path={ConsoleRouteConfig.configuration.href} element={<ConfigurationView />} />
              <Route path={ConsoleRouteConfig.cases.href} element={<CaseView />} />
            </Routes>
          }
          // splitPanel={
          //   <SplitPanel header="Split panel header">
          //     Split panel content
          //   </SplitPanel>
          // }
        />
      </HashRouter>
    </I18nProvider>
  );
}
