import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { encodeBase64RemovePrefix } from "../common/helpers";
import { InjectDataToModelResponse } from "../common/types/responses";
import { InjectDataToModelRequest } from "../common/types/requests";

const UNIFIED_WORKFLOW_API_URL = EndpointConfigs.unifiedWorkflowApiUrl();

export const injectDataToModel = async (props: InjectDataToModelRequest) => {
  const response = await axios.post(`${UNIFIED_WORKFLOW_API_URL}`, props, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "InjectDataToModel",
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to inject data to model");
  }
  return response.data as InjectDataToModelResponse;
};
