import * as React from "react";

import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";

import { CaseContext } from "../common/types/models";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { CaseContextTable } from "../component/table/CaseContextTable";
import { listCaseContexts } from "../api/caseContext";
import { CreateCaseWizard } from "../component/wizard/CreateCaseWizard";
import { CaseOverview } from "../component/preview/CaseOverview";

export const CaseView = () => {
  const [caseContextsLoading, setCaseContextsLoading] = React.useState(false);
  const [currentCaseContext, setCurrentCaseContext] = React.useState<CaseContext | null>(null);

  const [notifications, setNotifications] = React.useState<FlashbarProps.MessageDefinition[]>([
    {
      type: "warning",
      content: "Page under construction",
      dismissible: false,
      id: "under_construction",
    },
  ]);
  const [caseContexts, setCaseContexts] = React.useState<CaseContext[]>([]);

  const refreshCaseContexts = async () => {
    setCaseContextsLoading(true);
    const caseContexts = await listCaseContexts("demo-user");
    setCaseContexts(caseContexts);
    setCaseContextsLoading(false);
  };

  React.useEffect(() => {
    refreshCaseContexts();
  }, []);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Cases
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <CreateCaseWizard
          onSubmit={() => {
            refreshCaseContexts();
          }}
          onCancel={() => {}}
        />
        <CaseContextTable
          items={caseContexts}
          loading={caseContextsLoading}
          onRefresh={refreshCaseContexts}
          onItemClicked={(item) => {
            setCurrentCaseContext(item);
          }}
        />

        <CaseOverview caseContext={currentCaseContext} />
      </SpaceBetween>
    </ContentLayout>
  );
};
