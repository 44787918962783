import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { Answer } from "../common/types/models";
import { UploadSourceRequest } from "../common/types/requests";

export interface FileMetadata {
  source_type: string;
  user_id: string;
}

export const FEATURE_EXTRACTION_API_URL = EndpointConfigs.featureExtractionApiUrl();

export const uploadSource = async (request: UploadSourceRequest) => {
  const response = await axios.post(FEATURE_EXTRACTION_API_URL, request.content, {
    headers: {
      "Content-Type": "text/html",
      "X-Flux-Target": "UploadSource",
      "X-Flux-User-Id": request.userId,
      "X-Flux-Source-Type": request.sourceType,
      "X-Flux-Source-Format": request.sourceFormat,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to upload file");
  }
  return response.data.s3Path as string;
};

export const extractFeatures = async (s3FilePaths: string[], sourceType: string) => {
  if (s3FilePaths.length === 0) {
    throw new Error("No files to extract features from");
  }
  const response = await axios.post(
    EndpointConfigs.featureExtractionApiUrlV0(),
    {
      s3_file_paths: s3FilePaths,
      source_type: sourceType,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ExtractFeatures",
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to extract features");
  }
  return response.data.results as Record<string, Answer[]>;
};
