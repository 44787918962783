import ContentLayout from "@cloudscape-design/components/content-layout";
import { HomeBanner } from "../component/HomeBanner";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";

export const HomeView = () => {
  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Console Home
        </Header>
      }
    >
      <HomeBanner />
    </ContentLayout>
  );
};
