import { FunctionComponent } from "react";
import { AnswerType } from "../../common/enums";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Textarea from "@cloudscape-design/components/textarea";
import Select from "@cloudscape-design/components/select";
import { Answer } from "../../common/types/models";
import { FluxWidgetProps } from "../../common/standards";

export interface AnswerViewProps extends FluxWidgetProps {
  answer: Answer;
  index: string;
  inEdit: boolean;
  onChange: (a: Answer) => void;
}

export const AnswerView: FunctionComponent<AnswerViewProps> = ({ answer, index, inEdit, onChange }) => {
  return (
    <SpaceBetween size={"s"}>
      <FormField label={`Value #${index}`}>
        <Textarea readOnly={!inEdit} value={answer.value} onChange={(e) => onChange({ ...answer, value: e.detail.value })} />
      </FormField>
      <FormField label={`Unit #${index}`}>
        <Input readOnly={!inEdit} value={answer.unit || ""} onChange={(e) => onChange({ ...answer, unit: e.detail.value })} />
      </FormField>
      <FormField label={`Type #${index}`}>
        <Select
          readOnly={!inEdit}
          selectedOption={{
            label: answer.type,
            value: answer.type,
          }}
          onChange={({ detail }) => {
            onChange({ ...answer, type: detail.selectedOption.value as AnswerType });
          }}
          options={[
            { label: "string", value: AnswerType.STRING },
            { label: "number", value: AnswerType.NUMBER },
            { label: "date", value: AnswerType.DATE },
            { label: "boolean", value: AnswerType.BOOLEAN },
          ]}
        />
      </FormField>
      <FormField label={`Citation #${index}`}>
        <Textarea readOnly={!inEdit} value={answer.citation || ""} onChange={(e) => onChange({ ...answer, citation: e.detail.value })} />
      </FormField>
    </SpaceBetween>
  );
};
