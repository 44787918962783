import { SideNavigation } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ConsoleRouteConfig } from "../common/routes";

export const FluxSideNav = () => {
  const location = useLocation();

  const [activeHref, setActiveHref] = useState(`#`);

  useEffect(() => {
    setActiveHref(`#${location.pathname}`);
  }, [location]);

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{
        href: "#",
        text: "Flux",
      }}
      items={[
        {
          type: "link",
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          type: "link",
          text: ConsoleRouteConfig.demo.pageName,
          href: `#${ConsoleRouteConfig.demo.href}`,
        },
        {
          type: "link",
          text: ConsoleRouteConfig.cases.pageName,
          href: `#${ConsoleRouteConfig.cases.href}`,
        },
        {
          type: "link",
          text: ConsoleRouteConfig.configuration.pageName,
          href: `#${ConsoleRouteConfig.configuration.href}`,
        },
      ]}
    />
  );
};
