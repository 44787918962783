import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useState } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";

import { FluxWidgetProps } from "../../common/standards";
import { encodeBase64RemovePrefix, renderErrorMessage } from "../../common/helpers";
import { AxiosError } from "axios";
import Input from "@cloudscape-design/components/input";
import { createCaseContext, uploadSourceToCaseContext } from "../../api/caseContext";
import { SourceType } from "../../common/enums";

export interface CreateCaseWizardProps extends FluxWidgetProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export const CreateCaseWizard: FunctionComponent<CreateCaseWizardProps> = ({ onSubmit, onCancel, setNotifications }) => {
  const userId = "demo-user"; // TODO: Get user id from auth context
  const [caseName, setCaseName] = useState("");
  const [caseDescription, setCaseDescription] = useState("");

  const [termSheet, setTermSheet] = useState<File[]>([]);
  const [operatingStatement, setoperatingStatement] = useState<File[]>([]);
  const [rentRoll, setRentRoll] = useState<File[]>([]);
  const [appraisal, setAppraisal] = useState<File[]>([]);
  const [personalFinancialStatement, setPersonalFinancialStatement] = useState<File[]>([]);
  const [sponsorBackground, setSponsorBackground] = useState<File[]>([]);
  const [borrowerBackground, setBorrowerBackground] = useState<File[]>([]);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setCaseName("");
    setCaseDescription("");
    setTermSheet([]);
    setoperatingStatement([]);
    setRentRoll([]);
    setAppraisal([]);
    setPersonalFinancialStatement([]);
    setSponsorBackground([]);
    setBorrowerBackground([]);
    setActiveStepIndex(0);
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const caseContext = await createCaseContext({
        userId,
        name: caseName,
        description: caseDescription,
      });
      console.log("caseContext", caseContext);
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(termSheet[0]),
        sourceType: SourceType.TERM_SHEET,
        sourceFormat: "PDF",
      });
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(operatingStatement[0]),
        sourceType: SourceType.OPERATING_STATEMENT,
        sourceFormat: "PDF",
      });
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(rentRoll[0]),
        sourceType: SourceType.RENT_ROLL,
        sourceFormat: "PDF",
      });
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(appraisal[0]),
        sourceType: SourceType.APPRAISAL,
        sourceFormat: "PDF",
      });
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(personalFinancialStatement[0]),
        sourceType: SourceType.PERSONAL_FINANCIAL_STATEMENT,
        sourceFormat: "PDF",
      });
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(sponsorBackground[0]),
        sourceType: SourceType.SPONSOR_BACKGROUND,
        sourceFormat: "PDF",
      });
      await uploadSourceToCaseContext({
        userId,
        contextId: caseContext.contextId,
        content: await encodeBase64RemovePrefix(borrowerBackground[0]),
        sourceType: SourceType.BORROWER_BACKGROUND,
        sourceFormat: "PDF",
      });
    } catch (error) {
      if (setNotifications) {
        const transformedError = error as AxiosError;
        setNotifications([
          {
            header: "Failed to upload file(s)",
            type: "error",
            content: renderErrorMessage(transformedError),
            dismissible: true,
            dismissLabel: "Dismiss",
            onDismiss: () => setNotifications([]),
            id: "failed_to_upload_files",
          },
        ]);
      }
    } finally {
      setLoading(false);
      reset();
    }
  };

  const i18nStrings = {
    uploadButtonText: (e: any) => (e ? "Choose files" : "Choose file"),
    dropzoneText: (e: any) => (e ? "Drop files to upload" : "Drop file to upload"),
    removeFileAriaLabel: (e: number) => `Remove file ${e + 1}`,
    limitShowFewer: "Show fewer files",
    limitShowMore: "Show more files",
    errorIconAriaLabel: "Error",
  };

  const CHOOSE_FILE = "Choose file from your device";
  const CONSTRAINT_TEXT = "Please upload files in PDF format";

  return (
    <Container>
      <Wizard
        onSubmit={() => {
          onSubmit();
          handleSubmit();
        }}
        onCancel={() => {
          reset();
          onCancel();
        }}
        isLoadingNextStep={loading}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Create",
          optional: "optional",
        }}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Basic Information",
            info: <Link variant="info">Info</Link>,
            description: "Provide some descriptions for the case",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Case Name">
                    <Input value={caseName} placeholder="My Case Name" onChange={(event) => setCaseName(event.detail.value)} />
                  </FormField>
                  <FormField label="Case Description">
                    <Input value={caseDescription} placeholder="Simple description" onChange={(event) => setCaseDescription(event.detail.value)} />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Upload Source Documents",
            info: <Link variant="info">Info</Link>,
            description: "First we need to collect some information",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="m">
                  <FormField label="Term Sheet" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setTermSheet(detail.value)}
                      value={termSheet}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Operating Statement" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setoperatingStatement(detail.value)}
                      value={operatingStatement}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Rent Roll" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setRentRoll(detail.value)}
                      value={rentRoll}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Appraisal" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setAppraisal(detail.value)}
                      value={appraisal}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Personal Financial Statement" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setPersonalFinancialStatement(detail.value)}
                      value={personalFinancialStatement}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Sponsor Background" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setSponsorBackground(detail.value)}
                      value={sponsorBackground}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Borrower Background" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setBorrowerBackground(detail.value)}
                      value={borrowerBackground}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Review and Create",
            content: (
              <SpaceBetween size="xs">
                <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
                  Source Documents
                </Header>
                <KeyValuePairs
                  columns={3}
                  items={[
                    {
                      label: "User ID",
                      value: userId,
                    },
                    {
                      label: "Case Name",
                      value: caseName,
                    },
                    {
                      label: "Case Description",
                      value: caseDescription,
                    },
                    {
                      label: "Term Sheet",
                      value: termSheet.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Operating Statement",
                      value: operatingStatement.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Rent Roll",
                      value: rentRoll.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Appraisal",
                      value: appraisal.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Personal Financial Statement",
                      value: personalFinancialStatement.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Sponsor Background",
                      value: sponsorBackground.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Borrower Background",
                      value: borrowerBackground.map((file) => file.name).join(", "),
                    },
                  ]}
                />
              </SpaceBetween>
            ),
          },
        ]}
      />
    </Container>
  );
};
