// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from "react";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { ConsoleRouteConfig } from "../common/routes";
import Container from "@cloudscape-design/components/container";

export function HomeBanner() {
  const currentDate = () => {
    const date = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options as any);
  };

  return (
    <Box padding={{ top: "xs", bottom: "l" }}>
      <div>
        <Container
          media={{
            content: <img src="/images/flux_logo.png" alt="placeholder" style={{ padding: "40px", boxSizing: "border-box" }} />,
            height: 400,
            position: "top",
          }}
          footer={
            <div className="container-media-footer">
              <SpaceBetween size="s" direction="horizontal">
                {/* <Button variant="primary" fullWidth={true}>
                  Get started
                </Button> */}
                <Button fullWidth={true} variant="primary" href={`#${ConsoleRouteConfig.demo.href}`}>
                  View demo
                </Button>
              </SpaceBetween>
            </div>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            <SpaceBetween direction="vertical" size="xxs">
              <Box variant="small"> {currentDate()} </Box>
              <Box variant="h2">Flux: Reinvent Commercial Underwriting for the Modern Bank</Box>
            </SpaceBetween>
            <span>An AI-powered solution that can automate the underwriting process for your bank without cutting corners.</span>
          </SpaceBetween>
        </Container>
      </div>
    </Box>
  );
}
