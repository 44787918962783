import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useEffect, useState } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import { Namespace, ReportGenerationStep, SourceType } from "../../common/enums";
import { FluxReport, ReportMetadata } from "../../common/types/models";
import { createReport, updateReport } from "../../api/savedReports";
import { extractFeatures, uploadSource } from "../../api/featureExtraction";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import { BasicInformation } from "./BasicInformation";
import { StatusIndicatorProps } from "@cloudscape-design/components/status-indicator";
import Steps from "@cloudscape-design/components/steps";
import { REPORT_GENERATION_WORKFLOW } from "../../common/workflows";
import { FluxWidgetProps } from "../../common/standards";
import { encodeBase64RemovePrefix, renderErrorMessage } from "../../common/helpers";
import { AxiosError } from "axios";

export interface CollectionWizardProps extends FluxWidgetProps {
  onSubmit: () => void;
  onSuccess: (report: FluxReport) => void;
  onCancel: () => void;
}

export const CollectionWizard: FunctionComponent<CollectionWizardProps> = ({ onSubmit, onSuccess, onCancel, setNotifications }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [termSheet, setTermSheet] = useState<File[]>([]);
  const [operatingStatement, setoperatingStatement] = useState<File[]>([]);
  const [rentRoll, setRentRoll] = useState<File[]>([]);
  const [appraisal, setAppraisal] = useState<File[]>([]);
  const [personalFinancialStatement, setPersonalFinancialStatement] = useState<File[]>([]);
  const [sponsorBackground, setSponsorBackground] = useState<File[]>([]);
  const [borrowerBackground, setBorrowerBackground] = useState<File[]>([]);
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const defaultState: Record<ReportGenerationStep, StatusIndicatorProps.Type> = {
    [ReportGenerationStep.INITIALIZING]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_TERM_SHEET]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_OPERATING_STATEMENT]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_APPRAISAL]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_RENT_ROLL]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_PFS]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_SPONSOR_BACKGROUND]: "pending",
    [ReportGenerationStep.EXTRACT_FEATURES_BORROWER_BACKGROUND]: "pending",
  };

  const [statusRecords, setStatusRecords] = useState<Record<ReportGenerationStep, StatusIndicatorProps.Type>>(defaultState);
  const [workflowStarted, setWorkflowStarted] = useState(false);
  const userId = "demo-user"; // TODO: Get user id from auth context
  const [reportMetadata, setReportMetadata] = useState<ReportMetadata>({
    reportName: "",
    reportDescription: "",
    sourceFileS3Paths: ["-"],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = termSheet.map((file) => {
      if (file.type !== "application/pdf") {
        return "Please upload files in PDF format";
      }
      return "";
    });
    setFileErrors(errors);
  }, [termSheet]);

  const uploadFiles = async (files: File[], sourceType: SourceType) => {
    try {
      const s3FilePaths = await Promise.all(
        files.map(async (file) => {
          const base64encoded = await encodeBase64RemovePrefix(file);
          return await uploadSource({
            userId,
            content: base64encoded,
            sourceType,
            sourceFormat: "PDF",
          });
        })
      );
      return s3FilePaths;
    } catch (error) {
      if (setNotifications) {
        const transformedError = error as AxiosError;
        setNotifications([
          {
            header: "Failed to upload file(s)",
            type: "error",
            content: renderErrorMessage(transformedError),
            dismissible: true,
            dismissLabel: "Dismiss",
            onDismiss: () => setNotifications([]),
            id: "failed_to_upload_files",
          },
        ]);
      }
      return undefined;
    }
  };

  const executeRun = async (
    generationStep: ReportGenerationStep,
    sourceType: SourceType,
    resultsKey: Namespace,
    results: FluxReport,
    runState: Record<ReportGenerationStep, StatusIndicatorProps.Type>,
    filesMap: Record<SourceType, File[]>
  ) => {
    if (filesMap[sourceType].length === 0) return;
    try {
      runState[generationStep] = "success";
      setStatusRecords({ ...runState });
      runState[generationStep] = "loading";
      setStatusRecords({ ...runState });
      const s3FilePaths = await uploadFiles(filesMap[sourceType], sourceType);
      if (!s3FilePaths) {
        runState[generationStep] = "error";
        setStatusRecords({ ...runState });
        return;
      }
      results.reportMetadata.sourceFileS3Paths.push(...s3FilePaths);
      results.data = {
        ...results.data,
        [resultsKey]: await extractFeatures(s3FilePaths, sourceType),
      };
      await updateReport(results);
      runState[generationStep] = "success";
      setStatusRecords({ ...runState });
    } catch (error) {
      runState[generationStep] = "error";
      setStatusRecords({ ...runState });
      console.error("Encountered error while executing run", error);
    }
  };

  if (workflowStarted) {
    return (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <Button
                onClick={() => {
                  setWorkflowStarted(false);
                  setStatusRecords(defaultState);
                }}
              >
                Close
              </Button>
            }
          >
            Generation Status
          </Header>
        }
      >
        <Steps
          steps={
            [
              {
                type: ReportGenerationStep.INITIALIZING,
                label: "Initializing",
              },
              ...REPORT_GENERATION_WORKFLOW,
            ].map((step) => ({
              status: statusRecords[step.type],
              header: step.label,
            })) || []
          }
        />
      </Container>
    );
  }

  const handleSubmit = async () => {
    setLoading(true);
    setWorkflowStarted(true);
    try {
      const runState = { ...defaultState };
      runState[ReportGenerationStep.INITIALIZING] = "loading";
      setStatusRecords({ ...runState });
      const reportId = await createReport(userId, reportMetadata);
      console.log("Report Created with ID: ", reportId);
      if (!reportId) {
        runState[ReportGenerationStep.INITIALIZING] = "error";
        setStatusRecords({ ...runState });
        return;
      }
      const uploadedFileMap: Record<SourceType, File[]> = {
        [SourceType.TERM_SHEET]: termSheet,
        [SourceType.OPERATING_STATEMENT]: operatingStatement,
        [SourceType.APPRAISAL]: appraisal,
        [SourceType.RENT_ROLL]: rentRoll,
        [SourceType.PERSONAL_FINANCIAL_STATEMENT]: personalFinancialStatement,
        [SourceType.SPONSOR_BACKGROUND]: sponsorBackground,
        [SourceType.BORROWER_BACKGROUND]: borrowerBackground,
      };
      const results = {
        reportId,
        userId,
        reportMetadata: {
          ...reportMetadata,
          sourceFileS3Paths: [] as string[],
        },
        data: {},
      };
      runState[ReportGenerationStep.INITIALIZING] = "success";
      setStatusRecords({ ...runState });
      for (const step of REPORT_GENERATION_WORKFLOW) {
        await executeRun(step.type, step.sourceType, step.resultsKey, results, runState, uploadedFileMap);
      }
      onSuccess(results);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const i18nStrings = {
    uploadButtonText: (e: any) => (e ? "Choose files" : "Choose file"),
    dropzoneText: (e: any) => (e ? "Drop files to upload" : "Drop file to upload"),
    removeFileAriaLabel: (e: number) => `Remove file ${e + 1}`,
    limitShowFewer: "Show fewer files",
    limitShowMore: "Show more files",
    errorIconAriaLabel: "Error",
  };

  const CHOOSE_FILE = "Choose file from your device";
  const CONSTRAINT_TEXT = "Please upload files in PDF format";

  return (
    <Container>
      <Wizard
        onSubmit={() => {
          onSubmit();
          handleSubmit();
        }}
        onCancel={() => {
          setActiveStepIndex(0);
          onCancel();
        }}
        isLoadingNextStep={loading}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Generate",
          optional: "optional",
        }}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Basic Information",
            info: <Link variant="info">Info</Link>,
            description: "Provide some descriptions for the case",
            content: <BasicInformation reportMetadata={reportMetadata} onChange={setReportMetadata} />,
          },
          {
            title: "Upload Source Documents",
            info: <Link variant="info">Info</Link>,
            description: "First we need to collect some information",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="m">
                  <FormField label="Term Sheet" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setTermSheet(detail.value)}
                      value={termSheet}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Operating Statement" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setoperatingStatement(detail.value)}
                      value={operatingStatement}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Rent Roll" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setRentRoll(detail.value)}
                      value={rentRoll}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Appraisal" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setAppraisal(detail.value)}
                      value={appraisal}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Personal Financial Statement" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setPersonalFinancialStatement(detail.value)}
                      value={personalFinancialStatement}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Sponsor Background" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setSponsorBackground(detail.value)}
                      value={sponsorBackground}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Borrower Background" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setBorrowerBackground(detail.value)}
                      value={borrowerBackground}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Review and Generate",
            content: (
              <SpaceBetween size="xs">
                <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
                  Source Documents
                </Header>
                <KeyValuePairs
                  columns={3}
                  items={[
                    {
                      label: "User ID",
                      value: userId,
                    },
                    {
                      label: "Report Name",
                      value: reportMetadata.reportName,
                    },
                    {
                      label: "Report Description",
                      value: reportMetadata.reportDescription,
                    },
                    {
                      label: "Term Sheet",
                      value: termSheet.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Operating Statement",
                      value: operatingStatement.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Rent Roll",
                      value: rentRoll.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Appraisal",
                      value: appraisal.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Personal Financial Statement",
                      value: personalFinancialStatement.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Sponsor Background",
                      value: sponsorBackground.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Borrower Background",
                      value: borrowerBackground.map((file) => file.name).join(", "),
                    },
                  ]}
                />
              </SpaceBetween>
            ),
          },
        ]}
      />
    </Container>
  );
};
