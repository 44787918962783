export enum SourceType {
  TERM_SHEET = "TERM_SHEET",
  RENT_ROLL = "RENT_ROLL",
  OPERATING_STATEMENT = "OPERATING_STATEMENT",
  APPRAISAL = "APPRAISAL",
  PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
  SPONSOR_BACKGROUND = "SPONSOR_BACKGROUND",
  BORROWER_BACKGROUND = "BORROWER_BACKGROUND",
}

export enum Namespace {
  TERM_SHEET = "termSheet",
  RENT_ROLL = "rentRoll",
  OPERATING_STATEMENT = "operatingStatement",
  APPRAISAL = "appraisal",
  PERSONAL_FINANCIAL_STATEMENT = "personalFinancialStatement",
  SPONSOR_BACKGROUND = "sponsorBackground",
  BORROWER_BACKGROUND = "borrowerBackground",
}

export enum MemoComponentSectionType {
  BORROWER = "BORROWER",
  LOAN_TERMS = "LOAN_TERMS",
  SPONSOR_BACKGROUND = "SPONSOR_BACKGROUND",
  RENT_ROLL_ANALYSIS = "RENT_ROLL_ANALYSIS",
  HISTORICAL_FINANCIALS = "HISTORICAL_FINANCIALS",
  PRO_FORMA_ANALYSIS = "PRO_FORMA_ANALYSIS",
  COLLATERAL_DESCRIPTION_AND_VALUATION = "COLLATERAL_DESCRIPTION_AND_VALUATION",
  GUARANTOR_BALANCE_SHEET_ANALYSIS = "GUARANTOR:BALANCE_SHEET_ANALYSIS",
  GUARANTOR_INCOME_STATEMENT_ANALYSIS = "GUARANTOR:INCOME_STATEMENT_ANALYSIS",
}

export enum MemoOverallSectionType {
  SUMMARY_AND_RECOMMENDATION = "SUMMARY_AND_RECOMMENDATION",
  STRENGTHS_AND_WEAKNESSES = "STRENGTHS_AND_WEAKNESSES",
  PROJECT_DESCRIPTION = "PROJECT_DESCRIPTION",
}

export enum CsvDataType {
  PRO_FORMA = "PRO_FORMA",
  RENT_ROLL = "RENT_ROLL",
  HISTORICAL_FINANCIALS = "HISTORICAL_FINANCIALS",
  GUARANTOR_BALANCE_SHEET = "GUARANTOR_BALANCE_SHEET",
  GUARANTOR_CASH_FLOW = "GUARANTOR_CASH_FLOW",
}

export enum AnswerType {
  STRING = "string",
  NUMBER = "number",
  DATE = "date",
  BOOLEAN = "boolean",
}

export enum ReportGenerationStep {
  INITIALIZING = "INITIALIZING",
  EXTRACT_FEATURES_TERM_SHEET = "EXTRACT_FEATURES_TERM_SHEET",
  EXTRACT_FEATURES_OPERATING_STATEMENT = "EXTRACT_FEATURES_OPERATING_STATEMENT",
  EXTRACT_FEATURES_APPRAISAL = "EXTRACT_FEATURES_APPRAISAL",
  EXTRACT_FEATURES_RENT_ROLL = "EXTRACT_FEATURES_RENT_ROLL",
  EXTRACT_FEATURES_PFS = "EXTRACT_FEATURES_PFS",
  EXTRACT_FEATURES_BORROWER_BACKGROUND = "EXTRACT_FEATURES_BORROWER_BACKGROUND",
  EXTRACT_FEATURES_SPONSOR_BACKGROUND = "EXTRACT_FEATURES_SPOSOR_BACKGROUND",
}