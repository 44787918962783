import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import FileUpload from "@cloudscape-design/components/file-upload";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { FunctionComponent, useState } from "react";
import { injectDataToModel } from "../api/unifiedWorkflow";
import { AxiosError } from "axios";
import { encodeBase64RemovePrefix, renderErrorMessage } from "../common/helpers";
import { FluxWidgetProps } from "../common/standards";

export const ProcessModel: FunctionComponent<FluxWidgetProps> = ({ setNotifications }) => {
  const [reportId, setReportId] = useState<string>("");
  const [configurationId, setConfigurationId] = useState<string>("");
  const [modelFile, setModelFile] = useState<File[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  return (
    <Container header={<Header>Process Model</Header>}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button
                variant="primary"
                loading={processing}
                onClick={async () => {
                  try {
                    setProcessing(true);
                    const base64encoded = await encodeBase64RemovePrefix(modelFile[0]);
                    const response = await injectDataToModel({
                      userId: "demo-user",
                      reportId: reportId.trim(),
                      configurationId: configurationId.trim(),
                      template: base64encoded,
                    });
                    if (response) {
                      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.output}`;
                      const downloadLink = document.createElement("a");
                      downloadLink.href = linkSource;
                      downloadLink.download = "output.xlsx";
                      downloadLink.click();
                    }
                  } catch (error: any) {
                    const transformedError = error as AxiosError;
                    if (setNotifications) {
                      setNotifications([
                        {
                          header: "Failed to process model",
                          type: "error",
                          content: renderErrorMessage(transformedError),
                          dismissible: true,
                          dismissLabel: "Dismiss",
                          onDismiss: () => setNotifications([]),
                          id: "failed_to_process_model",
                        },
                      ]);
                    }
                    window.scrollTo(0, 0);
                  } finally {
                    setProcessing(false);
                  }
                }}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="m">
            <FormField label="Report ID">
              <Input value={reportId} onChange={(e) => setReportId(e.detail.value)} />
            </FormField>
            <FormField label="Configuration ID">
              <Input value={configurationId} onChange={(e) => setConfigurationId(e.detail.value)} />
            </FormField>
            <FormField label="Model Template File" description="Custom financial model in XLSX format">
              <FileUpload
                onChange={(e) => setModelFile(e.detail.value)}
                value={modelFile}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                  dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                constraintText="Upload file in XLSX format"
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
};
