import { AxiosError } from "axios";

export const encodePdfFileBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const encodeBase64RemovePrefix = async (file: File) => {
  const res = (await encodePdfFileBase64(file)) as string;
  console.log(res.split(",")[0]);
  return res.split(",")[1];
};

export const renderErrorMessage = (error: AxiosError) => {
  const response_data = error.response?.data as any;
  if (response_data) {
    if (response_data.message) {
      return response_data.message;
    } else {
      return error.message;
    }
  }
  return error.message;
};

export const convertISOTimestampToLocaleString = (timestamp: string) => {
  return new Date(timestamp).toLocaleString();
};

export const convertCamelCaseToTitleCase = (camelCase: string) => {
  const camelCasePattern = /([a-z])([A-Z])/g;
  const titleCase = camelCase.replace(camelCasePattern, "$1 $2");
  return titleCase.replace(/\b\w/g, (char) => char.toUpperCase());
};
