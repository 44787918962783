import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { CreateComponentSectionRequest } from "../common/types/requests";
import { MemoSection } from "../common/types/models";

const REPORT_GENERATION_API_URL = EndpointConfigs.reportGenerationApiUrl();

export const generateMemo = async (title: string, sections: MemoSection[]) => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {
      title: title,
      sections: sections,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "GenerateMemo",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to compose memo");
    throw new Error("Failed to compose memo");
  }
  const base64encoded = response.data.content as string;
  const file_name = response.data.metadata.file_name as string;
  return { base64encoded, file_name };
};

export const createComponentSection = async (props: CreateComponentSectionRequest) => {
  const response = await axios.post(REPORT_GENERATION_API_URL, props, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "CreateComponentSection",
    },
  });
  if (response.status !== 200) {
    console.error("Failed to create component section");
    throw new Error("Failed to create component section");
  }
  return response.data as MemoSection;
};

export const createOverallSection = async (type: string, sections: MemoSection[]) => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {
      type,
      sections,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateOverallSection",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to create overall section");
    throw new Error("Failed to create overall section");
  }
  return response.data as MemoSection;
};
