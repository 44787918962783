export enum ConsolePageIds {
  HOME = "home",
  DEMO = "demo",
  CONFIGURATION = "configuration",
  CASES = "cases",
}

export interface RouteConfig {
  href: string;
  pageName: string;
}

export const ConsoleRouteConfig: Record<ConsolePageIds, RouteConfig> = {
  [ConsolePageIds.HOME]: { href: "/", pageName: "Home" },
  [ConsolePageIds.DEMO]: { href: "/demo", pageName: "Demo" },
  [ConsolePageIds.CONFIGURATION]: { href: "/configuration", pageName: "Configuration" },
  [ConsolePageIds.CASES]: { href: "/cases", pageName: "Cases" },
};
