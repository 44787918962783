import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import Container from "@cloudscape-design/components/container";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Header from "@cloudscape-design/components/header";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { FunctionComponent, useEffect, useState } from "react";

import { AnswerView } from "./AnswerView";
import SegmentedControl from "@cloudscape-design/components/segmented-control";
import FormField from "@cloudscape-design/components/form-field";
import Button from "@cloudscape-design/components/button";
import Select from "@cloudscape-design/components/select";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { updateReport } from "../../api/savedReports";
import { FluxReport } from "../../common/types/models";
import { convertCamelCaseToTitleCase } from "../../common/helpers";
import { FluxWidgetProps } from "../../common/standards";

export interface PreviewBoardProps extends FluxWidgetProps {
  report: FluxReport | null;
  onSave: (report: FluxReport) => void;
}

export const PreviewBoard: FunctionComponent<PreviewBoardProps> = ({ report, onSave, setNotifications }) => {
  const [mode, setMode] = useState("view");
  const [selectedNamespace, setSelectedNamespace] = useState<OptionDefinition | null>(null);
  const [currentReport, setCurrentReport] = useState<FluxReport | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    setCurrentReport(report);
    setSelectedNamespace(null);
  }, [report]);

  const createNamespaceOptionsFromReport = (report: FluxReport) => {
    // list of all top level keys in the data object
    const namespaces = Object.keys(report.data || {});
    return namespaces
      .map((namespace) => ({
        label: convertCamelCaseToTitleCase(namespace),
        value: namespace,
      }))
      .sort((a, b) => a.value.localeCompare(b.value));
  };
  if (!currentReport) return null;

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <SegmentedControl
                selectedId={mode}
                onChange={({ detail }) => setMode(detail.selectedId)}
                label="View mode"
                options={[
                  { text: "View", id: "view" },
                  { text: "Edit", id: "edit" },
                ]}
              />
              <Button
                variant="normal"
                disabled={mode === "view"}
                onClick={() => {
                  setCurrentReport(report);
                  setMode("view");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="normal"
                disabled={mode === "view"}
                loading={saveLoading}
                onClick={() => {
                  setSaveLoading(true);
                  updateReport(currentReport)
                    .then((_) => {
                      onSave(currentReport);
                    })
                    .catch((error) => {
                      console.error("Failed to save report", error);
                    })
                    .finally(() => {
                      setMode("view");
                      setSaveLoading(false);
                    });
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          Preview
        </Header>
      }
    >
      <SpaceBetween size={"m"}>
        <KeyValuePairs
          columns={4}
          items={[
            {
              label: "Report ID",
              value: currentReport.reportId,
            },
            {
              label: "User ID",
              value: currentReport.userId,
            },
            {
              label: "Report name",
              value: currentReport.reportMetadata.reportName,
            },
            {
              label: "Report description",
              value: currentReport.reportMetadata.reportDescription,
            },
          ]}
        />
        <FormField label="Document Namespace">
          <Select
            options={createNamespaceOptionsFromReport(currentReport)}
            selectedOption={selectedNamespace}
            onChange={({ detail }) => setSelectedNamespace(detail.selectedOption)}
          />
        </FormField>
        {Object.entries(currentReport.data[selectedNamespace?.value || ""] || {}).flatMap(([collectionName, items]) => (
          <ExpandableSection
            headerText={`${convertCamelCaseToTitleCase(collectionName)} (${items.length})`}
            key={`${selectedNamespace?.value}-${collectionName}`}
            headerDescription={collectionName}
          >
            <SpaceBetween size={"s"}>
              {items.map((item, index) => (
                <AnswerView
                  inEdit={mode === "edit"}
                  index={`${index + 1}`}
                  key={`${selectedNamespace?.value}-${collectionName}-${index}`}
                  answer={item}
                  onChange={(answer) => {
                    const modifiedReport = JSON.parse(JSON.stringify(currentReport));
                    modifiedReport.data[selectedNamespace?.value || ""][collectionName][index] = answer;
                    setCurrentReport(modifiedReport);
                  }}
                />
              ))}
            </SpaceBetween>
          </ExpandableSection>
        ))}
      </SpaceBetween>
    </Container>
  );
};

const PreviewLoading = () => {
  return (
    <Container header={<Header variant="h2">Preview</Header>}>
      <div aria-live="polite">
        <LoadingBar variant="gen-ai" />
      </div>
    </Container>
  );
};
