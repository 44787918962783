import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { Configuration, ReportMetadata, FluxReport } from "../common/types/models";

const SAVED_REPORTS_API_URL = EndpointConfigs.savedReportsApiUrl();

export const createReport = async (userId: string, reportMetadata: ReportMetadata) => {
  console.log(reportMetadata);
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
      reportMetadata,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateReport",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to create report");
    return null;
  }
  return response.data.reportId as string;
};

export const updateReport = async (report: FluxReport) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      report,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "UpdateReport",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to update report");
  }
  return response.data.reportId as string;
};

export const saveCsvResults = async (userId: string, csvFile: File, csvDataType: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
      type: csvDataType,
      csv: await csvFile.text(),
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "SaveCsvResults",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to save CSV results");
    throw new Error("Failed to save CSV results");
  }
  return response.data.s3Path as string;
};

export const listReports = async (userId: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListReports",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list reports");
    return [];
  }
  return response.data.reports as FluxReport[];
};

export const deleteReport = async (reportId: string, userId: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      reportId,
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "DeleteReport",
      },
    }
  );
  if (response.status !== 200) {
    console.error(`Failed to delete report with ID: ${reportId}`);
    return false;
  }
  return true;
};

export const createConfiguration = async (userId: string, name: string, content: string) => {
  const configuration: Configuration = {
    configurationId: "",
    userId,
    name,
    content,
  };
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      configuration,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateConfiguration",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to create configuration");
    throw new Error("Failed to create configuration");
  }
  return response.data.configurationId as string;
};

export const updateConfiguration = async (userId: string, configurationId: string, name: string, content: string) => {
  const configuration: Configuration = {
    configurationId,
    userId,
    name,
    content,
  };
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      configuration,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "UpdateConfiguration",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to update configuration");
    throw new Error("Failed to update configuration");
  }
  return response.data.configurationId as string;
};

export const listConfigurations = async (userId: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListConfigurations",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list configurations");
    return [];
  }
  return response.data.configurations as Configuration[];
};

export const deleteConfiguration = async (configurationId: string, userId: string) => {
  await axios.post(
    SAVED_REPORTS_API_URL,
    {
      configurationId,
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "DeleteConfiguration",
      },
    }
  );
};
