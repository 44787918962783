import { FunctionComponent, useState } from "react";
import { FluxWidgetProps } from "../../common/standards";
import { CaseContext } from "../../common/types/models";
import { Button, Container, CopyToClipboard, Header, ProgressBar, StatusIndicator } from "@cloudscape-design/components";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import { createKnowledgeFromCaseContext } from "../../api/caseContext";

export interface CaseOverviewProps extends FluxWidgetProps {
  caseContext: CaseContext | null;
}

export const CaseOverview: FunctionComponent<CaseOverviewProps> = ({ caseContext }) => {
  const [isCreatingKnowledge, setIsCreatingKnowledge] = useState<boolean>(false);

  const [numOfSources, setNumOfSources] = useState<number>(1);
  const [numOfSourcesProcessed, setNumOfSourcesProcessed] = useState<number>(0);

  if (!caseContext) return null;

  const renderSourceDocuments = (sourceS3Keys: string[]) => {
    return sourceS3Keys
      .filter((s3Key) => !s3Key.endsWith(".metadata.json"))
      .map((s3Key) => {
        const parts = s3Key.split("/");
        return parts.slice(3).join("/");
      });
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <Button
              loading={isCreatingKnowledge}
              onClick={async () => {
                setIsCreatingKnowledge(true);
                const documentSources = caseContext.sourceS3Keys.filter((o) => !o.endsWith(".metadata.json"));
                setNumOfSources(documentSources.length);
                for (const source of documentSources) {
                  await createKnowledgeFromCaseContext(caseContext.userId, caseContext.contextId, source);
                  setNumOfSourcesProcessed((prev) => prev + 1);
                }
                setIsCreatingKnowledge(false);
              }}
            >
              Create Knowledge
            </Button>
          }
        >
          Case Overview
        </Header>
      }
    >
      <KeyValuePairs
        columns={3}
        items={[
          {
            label: "Context ID",
            value: (
              <CopyToClipboard
                copyButtonAriaLabel="Copy Context ID"
                copyErrorText="Context ID failed to copy"
                copySuccessText="Context ID copied"
                textToCopy={caseContext.contextId}
                variant="inline"
              />
            ),
          },
          {
            label: "Name",
            value: caseContext.name,
          },
          {
            label: "Status",
            value: <StatusIndicator>{caseContext.status}</StatusIndicator>,
          },
          {
            label: "Description",
            value: caseContext.description,
          },
          {
            label: "Updated At",
            value: caseContext.lastUpdatedAt,
          },
          {
            label: "Knowledge Updated At",
            value: caseContext.lastKnowledgeUpdatedAt,
          },
          {
            label: "Source Documents",
            value: renderSourceDocuments(caseContext.sourceS3Keys).join(", "),
          },
          {
            label: "Knowledge Status",
            value: <ProgressBar value={(numOfSourcesProcessed / numOfSources) * 100} />,
          },
        ]}
      />
    </Container>
  );
};
