import { CsvDataType, MemoComponentSectionType, MemoOverallSectionType, Namespace, ReportGenerationStep, SourceType } from "./enums";
import { ComponentSectionWorkflowStep, OverallSectionWorkflowStep, ReportGenerationWorkflowStep } from "./types/workflows";

export const REPORT_GENERATION_WORKFLOW: ReportGenerationWorkflowStep[] = [
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_TERM_SHEET,
    label: "Term Sheet",
    sourceType: SourceType.TERM_SHEET,
    resultsKey: Namespace.TERM_SHEET,
  },
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_OPERATING_STATEMENT,
    label: "Operating Statement",
    sourceType: SourceType.OPERATING_STATEMENT,
    resultsKey: Namespace.OPERATING_STATEMENT,
  },
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_APPRAISAL,
    label: "Appraisal",
    sourceType: SourceType.APPRAISAL,
    resultsKey: Namespace.APPRAISAL,
  },
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_RENT_ROLL,
    label: "Rent Roll",
    sourceType: SourceType.RENT_ROLL,
    resultsKey: Namespace.RENT_ROLL,
  },
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_PFS,
    label: "Personal Financial Statement",
    sourceType: SourceType.PERSONAL_FINANCIAL_STATEMENT,
    resultsKey: Namespace.PERSONAL_FINANCIAL_STATEMENT,
  },
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_BORROWER_BACKGROUND,
    label: "Borrower Background",
    sourceType: SourceType.BORROWER_BACKGROUND,
    resultsKey: Namespace.BORROWER_BACKGROUND,
  },
  {
    type: ReportGenerationStep.EXTRACT_FEATURES_SPONSOR_BACKGROUND,
    label: "Sponsor Background",
    sourceType: SourceType.SPONSOR_BACKGROUND,
    resultsKey: Namespace.SPONSOR_BACKGROUND,
  },
];

export const COMPONENT_SECTION_WORKFLOW: ComponentSectionWorkflowStep[] = [
  {
    type: MemoComponentSectionType.LOAN_TERMS,
    label: "Loan Terms",
  },
  {
    type: MemoComponentSectionType.BORROWER,
    label: "Borrower",
  },
  {
    type: MemoComponentSectionType.SPONSOR_BACKGROUND,
    label: "Sponsor Background",
  },
  {
    type: MemoComponentSectionType.COLLATERAL_DESCRIPTION_AND_VALUATION,
    label: "Property Description",
  },
  {
    type: MemoComponentSectionType.RENT_ROLL_ANALYSIS,
    label: "Rent Roll Analysis",
    data: [
      {
        type: CsvDataType.RENT_ROLL,
      },
    ],
  },
  {
    type: MemoComponentSectionType.HISTORICAL_FINANCIALS,
    label: "Historical Financials",
    data: [
      {
        type: CsvDataType.HISTORICAL_FINANCIALS,
      },
    ],
  },
  {
    type: MemoComponentSectionType.PRO_FORMA_ANALYSIS,
    label: "Pro Forma Analysis",
    data: [
      {
        type: CsvDataType.PRO_FORMA,
      },
    ],
  },
  {
    type: MemoComponentSectionType.GUARANTOR_BALANCE_SHEET_ANALYSIS,
    label: "Guarantor Balance Sheet Analysis",
    data: [
      {
        type: CsvDataType.GUARANTOR_BALANCE_SHEET,
      },
    ],
  },
  {
    type: MemoComponentSectionType.GUARANTOR_INCOME_STATEMENT_ANALYSIS,
    label: "Guarantor Income Statement Analysis",
    data: [
      {
        type: CsvDataType.GUARANTOR_CASH_FLOW,
      },
    ],
  },
];

export const OVERALL_SECTION_WORKFLOW: OverallSectionWorkflowStep[] = [
  {
    type: MemoOverallSectionType.PROJECT_DESCRIPTION,
    label: "Project Description",
  },
  {
    type: MemoOverallSectionType.STRENGTHS_AND_WEAKNESSES,
    label: "Strengths and Weaknesses",
  },
  {
    type: MemoOverallSectionType.SUMMARY_AND_RECOMMENDATION,
    label: "Summary and Recommendation",
  },
];
